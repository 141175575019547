@media only screen and (max-width: 600px) {
    .wallImage {
        width: 100%;
        height: auto;
    }

    .pclass {
        width: 100%;
    }

    .title_1 {
        font-size: 20px;
        font-weight: 500;
    }

    .title_2 {
        font-size: 25px;
        font-weight: 900px;
    }

    .copyright {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .mainHeaderBox .titleSection h1 {
        color: #fff;
        text-align: center;
        font-size: 30px;
        padding: 0;
        margin: 0;
    }


    .mainHeaderBox .titleSection p {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-weight: 200;
    }

    .mainHeaderBox .titleSection{
        padding: 30px 20px 30px 20px;
        max-width: 1000px;
        margin: 180px auto 0px auto;
    }

    .mainImageContainer{
        width: 90%;
        text-align: center;
        margin: -300px auto 0px auto;

    }

}



@media only screen and (min-width: 768px) {
    .wallImage {
        width: 100%;
        height: auto;
    }

    .pclass {
        width: 100%;
        font-size: 25px;
    }

    .title_1 {
        font-size: 30px;
        font-weight: 500;
    }

    .title_2 {
        font-size: 35px;
        font-weight: 900px;
    }

    .copyright {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

}